<div class="modal-header d-flex justify-content-between">
  <h4
    class="modal-title text-dark fw-medium"
    id="modal-basic-title"
  >
    {{
      stockData?.transactionType === 'buy'
        ? 'Buy Stock'
        : stockData?.transactionType === 'sell'
        ? 'Sell Stock'
        : ''
    }}
  </h4>
  <button
    class="btn btn-sm btn-light"
    (click)="activeModal.dismiss()"
  >
    <i class="bi bi-x text-primary fs-4"></i>
  </button>
</div>
<div class="modal-body">
  <form
    [formGroup]="addPortfolio"
    (ngSubmit)="addStock()"
  >
    <div class="row">
      <div class="col-md-12">
        <div class="form-group stock-add-search">
          <label
            for="name"
            class="form-label"
            >Stock Name</label
          >
          <input
            type="text"
            class="form-control position-relative"
            id="name"
            formControlName="ticker"
            placeholder="Search stock"
            maxlength="12"
            readonly
          />
          <!-- @if ( (fc['ticker'].touched || fc['ticker'].dirty || isSubmitted) &&
          fc['ticker'].invalid) {
          <div>
            @if (fc['ticker'].errors?.['required']) {
            <div class="text-danger">Ticker is required.</div>
            }
          </div>
          } -->
        </div>
      </div>
      <div class="col-12">
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label
                for="volume"
                class="form-label"
                >Volume</label
              >
              <input
                type="text"
                class="form-control"
                id="volume"
                formControlName="volume"
                placeholder="Enter Volume"
                appOnlyNumber
                [ngClass]="{
                  'border-danger':
                    (fc['volume'].touched ||
                      fc['volume'].dirty ||
                      isSubmitted) &&
                    fc['volume'].invalid
                }"
              />
              @if ( (fc['volume'].touched || fc['volume'].dirty || isSubmitted)
              && fc['volume'].invalid) {
              <div>
                @if (fc['volume'].errors?.['required']) {
                <div class="text-danger">Volume is required.</div>
                }
              </div>
              }
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label
                for="price"
                class="form-label"
                >Buy Price</label
              >
              <input
                type="text"
                class="form-control"
                id="price"
                formControlName="price"
                placeholder="Enter Buy Price"
                appOnlyNumber
                [inputType]="'decimal'"
                [ngClass]="{
                  'border-danger':
                    (fc['price'].touched || fc['price'].dirty || isSubmitted) &&
                    fc['price'].invalid
                }"
              />
              @if ( (fc['price'].touched || fc['price'].dirty || isSubmitted) &&
              fc['price'].invalid) {
              <div>
                @if (fc['price'].errors?.['required']) {
                <div class="text-danger">Buy Price is required.</div>
                }
              </div>
              }
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label
                for="date"
                class="form-label"
                >Purchase Date</label
              >
              <div class="input-group">
                <input
                  class="form-control"
                  placeholder="MM/DD/YYYY"
                  name="date"
                  formControlName="date"
                  ngbDatepicker
                  #d2="ngbDatepicker"
                  (focus)="d2.toggle()"
                  (dateSelect)="d2.close()"
                  [ngStyle]="{
                    'border-color':
                      (fc['date'].touched || fc['date'].dirty || isSubmitted) &&
                      fc['date'].invalid
                        ? '#f64e60'
                        : ''
                  }"
                />
                <span
                  class="input-group-text cursor-pointer"
                  (click)="d2.toggle()"
                >
                  <i class="bi bi-calendar3"></i>
                </span>
              </div>
              @if((fc['date'].touched || fc['date'].dirty || isSubmitted) &&
              fc['date'].invalid){ @if((fc['date'].errors &&
              fc['date'].errors['required'])){
              <div class="text-danger">Purchase date is required.</div>
              } }
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label
                for="exchange"
                class="form-label"
                >Stock Exchange</label
              >
              <input
                type="text"
                class="form-control"
                id="exchange"
                formControlName="exchange"
                placeholder="Exchange"
                readonly
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="form-group">
        <label
          for="portfolio"
          class="form-label"
          >Select Portfolio</label
        >
        <div class="dropdown">
          <ng-select
            class="ng-select"
            [items]="portfolioList"
            bindLabel="name"
            bindValue="_id"
            [ariaPlaceholder]="'Select Portfolio'"
            placeholder="Select Portfolio"
            [searchable]="false"
            [clearable]="false"
            formControlName="portfolioId"
            [readonly]="stockData?.transactionType ==='sell'"
          >
          </ng-select>
        </div>
      </div>
    </div>

    <div class="modal-footer d-flex justify-content-end">
      <button
        type="submit"
        class="btn btn-primary"
      >
        Save
      </button>
    </div>
  </form>
</div>
