import { Injectable, signal } from '@angular/core';
import { SocketIoService } from './socket-io.service';
import { DashboardService } from './dashboard.service';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  isStockBuySell = signal(false);

  storeAllStockList: any[] = [];
  subscribeStockList = signal<any[]>([]);
  totalPortfolioValue = signal<object>({ portfolioBalance: 0 });

  timeToLocal(originalTime: any) {
    const d = new Date(originalTime * 1000);
    return (
      Date.UTC(
        d.getFullYear(),
        d.getMonth(),
        d.getDate(),
        d.getDate() + 2,
        0,
        0,
        0,
      ) / 1000
    );
  }

  filterDate(filterType: any) {
    const date = new Date();
    switch (filterType) {
      case 'second': {
        return new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate(),
          date.getHours(),
          date.getMinutes(),
          date.getSeconds(),
          0,
        ).getTime();
      }
      case 'minute': {
        return new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate(),
          date.getHours(),
          date.getMinutes(),
        ).getTime();
      }
      case 'hour': {
        return new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate(),
          date.getHours(),
        ).getTime();
      }
      case 'week': {
        // Calculate the start of the week (assuming Sunday as the first day of the week)
        const day = date.getDay(); // 0 (Sunday) to 6 (Saturday)
        const diff = date.getDate() - day; // Calculate the difference to get back to Sunday
        return new Date(date.setDate(diff)).setHours(0, 0, 0, 0);
      }
      case 'day': {
        return new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate(),
          date.getDate() + 2,
          0,
          0,
          0,
        ).getTime();
      }
      case 'month': {
        return new Date(
          date.getFullYear(),
          date.getMonth(),
          1,
          0,
          0,
          0,
          0,
        ).getTime();
      }
      case 'quarter': {
        return new Date(
          date.getFullYear(),
          Math.floor(date.getMonth() / 3) * 3,
          1,
          0,
          0,
          0,
          0,
        ).getTime();
      }
      case 'year': {
        return new Date(date.getFullYear(), 0, 1, 0, 0, 0, 0).getTime();
      }
      default: {
        return date.getTime();
      }
    }
  }

  constructor(private socketService: SocketIoService, private dashboardService:DashboardService) {}

  /**
   * Calculates the portfolio(all) grandTotal,
   * and sends the total portfolio balance to the header.
   */
  updateTotalInvestedValue() {
    this.dashboardService.getPortfolio({}).subscribe({
      next: (res: any) => {
        if (res?.data && !res?.data?.results) {
          const portfolioGrandTotal = res?.data.reduce((sum: any, obj: any) => sum + obj.grandTotal,0);
          this.totalPortfolioValue.set({portfolioBalance: portfolioGrandTotal,});
        } else{
          this.totalPortfolioValue.set({
            portfolioBalance: 0,
          });
        }
      },
      error: () => {
        this.totalPortfolioValue.set({
          portfolioBalance: 0,
        });
      },
    });
  }

  storeStockPosition(stockList: any[]) {
    const storeStockIndexes: any = {};
    stockList?.forEach((stock: any, index: any) => {
      storeStockIndexes[stock?.ticker] = index;
      const getSubscribeStockList: any[] = this.subscribeStockList();
      if (!getSubscribeStockList?.includes(stock?.ticker)) {
        this.subscribeStockList?.update((value) => {
          return [...value, stock?.ticker];
        });
        this.socketService?.subscribeSocket(stock?.ticker);
      }
    });
    return storeStockIndexes;
  }
}
