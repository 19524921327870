import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { dateFormate, dateToISOFormat } from '../../../../config/app.constant';
import { DashboardService } from '../../services/dashboard.service';
import {
  NgbActiveModal,
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDatepickerModule,
  NgbDropdownModule,
  NgbModule,
} from '@ng-bootstrap/ng-bootstrap';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import {
  CustomAdapter,
  CustomDateParserFormatter,
} from '../../custom/dateFormat';
import { OnlyNumberDirective } from '../../directives/only-number.directive';
import { NgSelectModule } from '@ng-select/ng-select';
import { AuthService } from '../../services/auth.service';
import { CommonService } from '../../services/common.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-stock-buy-sell-modal',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbDatepickerModule,
    OnlyNumberDirective,
    NgSelectModule,
    NgbModule,
    NgbDropdownModule,
  ],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
  templateUrl: './stock-buy-sell-modal.component.html',
  styleUrl: './stock-buy-sell-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StockBuySellModalComponent implements OnInit, OnDestroy {

  @Input() stockData:any
  subscriptions$: Subscription[] = [];

  isSubmitted = false;
  isStockListOpen = false;
  stockList: any[] = [];
  isLoading = false;
  portfolioList: any[] = [];
  userDetail: any;
  addPortfolio = new FormGroup({
    ticker: new FormControl(''),
    price: new FormControl(null, [Validators.required]),
    volume: new FormControl(null, [Validators.required]),
    date: new FormControl(dateFormate(new Date().toISOString().split('T')[0]), Validators.required),
    exchange: new FormControl(''),
    portfolioId: new FormControl(null),
  });

  get fc() {
    return this.addPortfolio.controls;
  }

  constructor(
    private authService: AuthService,
    private dashboardService: DashboardService,
    public activeModal: NgbActiveModal,
    private cdr: ChangeDetectorRef,
    private commonService: CommonService,
  ) {
    this.userDetail = this.authService.decryptedUserDetails();
    this.getPortfolioList();
  }

  ngOnInit(): void {
    this.getTickerDetail(this.stockData?.ticker);
    // this.addPortfolio.patchValue({ ticker: this.selectedStock?.ticker });
  }

  /**
   * getPortfolioList - Get the portfolio list API
   */
  getPortfolioList() {
    const getPortfolioApi=  this.dashboardService.getPortfolio({ sortBy: '_id:dsc' }).subscribe({
      next: (res: any) => {
        if (res?.data && res?.type == 'success') {
          this.portfolioList = res?.data || [];
          if (this.stockData?.transactionType === 'sell') {
            this.portfolioList = this.getTickerPortfolio(
              this.stockData?.ticker,
              this.portfolioList,
            );
          }
          const foundPortfolio = this.portfolioList.find(
            (portfolio: any) =>
              portfolio._id === this.userDetail?.currentPortfolio?._id,
          );
          this.addPortfolio.patchValue({
            portfolioId: foundPortfolio
              ? foundPortfolio._id
              : this.portfolioList[0]?._id,
          });

          this.cdr.markForCheck();
        } else {
          this.portfolioList = [];
          this.cdr.markForCheck();
        }
      },
      error: () => {
        this.portfolioList = [];
        this.cdr.markForCheck();
      },
    });

    this.subscriptions$.push(getPortfolioApi)
  }

  getTickerPortfolio(ticker: string, portfolios: any[]) {
    return portfolios.filter((portfolio: any) =>
      portfolio?.portfolioStockData.some(
        (stock: any) => stock.ticker === ticker,
      ),
    );
  }

  /**
   * getTickerDetail - Get the Ticker Detail  API
   */
  getTickerDetail(ticker: any) {
    const getTickerDetailApi = this.dashboardService
      .getTickerDetail(ticker)
      .subscribe({
        next: (res: any) => {
          if (res?.data) {
            this.addPortfolio.patchValue({
              ticker: this.stockData?.ticker,
              price:this.stockData?.price.toFixed(2),
              exchange: res?.data?.primary_exchange,
              
            });
            this.cdr.markForCheck();
          } else {
            this.portfolioList = [];
            this.cdr.markForCheck();
          }
        },
        error: () => {
          this.portfolioList = [];
          this.cdr.markForCheck();
        },
      });
    this.subscriptions$.push(getTickerDetailApi);
  }

  /**
   * addStock - open modal for add stock in portfolio
   */
  addStock() {
    this.isSubmitted = true;
    if (this.addPortfolio.valid) {
      const body = {
        ...this.addPortfolio.value,
        date: dateToISOFormat(this.addPortfolio.value.date),
        transactionType: this.stockData?.transactionType,
      };

      const addStockPortfolioApi = this.dashboardService
        .addStockPortfolioApi(body)
        .subscribe({
          next: (res: any) => {
            if (res?.data && res?.type == 'success') {
              this.commonService?.isStockBuySell?.set(true);
              this.activeModal.close();
              // this.cdr.markForCheck();
            }
          },
          error: () => {
            this.cdr.markForCheck();
            //
          },
        });
        this.subscriptions$.push(addStockPortfolioApi);
        this.cdr.markForCheck();
    }
  }

  /**
   * ngOnDestroy - Unsubscribe subscription variable
   */
  ngOnDestroy(): void {
    this.subscriptions$.forEach((sub: Subscription) => {
      if (sub) {
        sub.unsubscribe();
        
      }
    });
  }
}
