import { Injectable, signal } from "@angular/core";
import { io, Socket } from "socket.io-client";

import { SOCKET_END_POINT, SOCKET_SERVICE_ID } from "../../../environments/environment";
import { AuthService } from "./auth.service";



@Injectable({
  providedIn: "root",
})
export class SocketIoService {
  socket!: Socket;
  socketOnmessage:any= signal(null);
  constructor(private authService: AuthService) {}

  socketIoConnection() {
    try {
      this.socket = io(SOCKET_END_POINT, {
        transports: ["websocket"],
        auth: {
          jwtToken: this.authService.getLocalstorage("accessToken"),
          serviceId: SOCKET_SERVICE_ID,
        },
      });
    
  } catch (error:any) {
    console.log(`socket connection error :>> `,error);
  }
    this.socket.on("connect", () => {
      console.log(`socket connected :>> `);
    });
    this.socketOnMessageConnect();
  }

  subscribeSocket(stock: any) {
    this.socket.emit("subscribe", { type: `A.${stock}` });
    this.socket.emit("subscribe", { type: `AM.${stock}` });
    this.socket.emit("subscribe", { type: `T.${stock}` });
  }

  unsubscribeStock(stock: any) {
    this.socket.emit("unsubscribe", { type: `A.${stock}` });
    this.socket.emit("unsubscribe", { type: `AM.${stock}` });
    this.socket.emit("unsubscribe", { type: `T.${stock}` });
  }

  socketOnMessageConnect() {
    this.socket.on("message", (data) => {
      this.socketOnmessage.set(data);
    });
  }
  
}
