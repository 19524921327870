import { Injectable } from '@angular/core';
import {
  DASHBOARD,
  NEWS,
  PORTFOLIO,
  WATCH_LIST,
} from '../../../config/api.config';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(private httpService: HttpService) {}

  /**get watch list api */
  getWatchList() {
    return this.httpService.get(WATCH_LIST.GET_WATCH_LIST, false, false);
  }

  /**get watch list by id api */
  getWatchListById(watchListId: any) {
    return this.httpService.get(
      `${WATCH_LIST.GET_WATCH_LIST_BY_ID}/${watchListId}`,
      true,
      false,
    );
  }

  /**Add WatchList API */
  addWatchList(watchListData: any) {
    return this.httpService.post(
      WATCH_LIST.ADD_WATCH_LIST,
      watchListData,
      true,
      false,
    );
  }

  /**update watchList API */
  updateWatchList(watchListId: any, watchListData: any) {
    return this.httpService.put(
      `${WATCH_LIST.UPDATE_WATCH_LIST}/${watchListId}`,
      watchListData,
      true,
      false,
    );
  }

  /**remove watchList api */
  removeWatchList(watchListId: any) {
    return this.httpService.delete(
      `${WATCH_LIST.DELETE_WATCH_LIST}/${watchListId}`,
      true,
      false,
    );
  }

  /**Update ticker api */
  updateTicker(watchListId: any, watchListData: any) {
    return this.httpService.patch(
      `${WATCH_LIST.UPDATE_TICKER}/${watchListId}`,
      watchListData,
      true,
      false,
    );
  }

  /**Search stock api */
  searchStock(queryParams: any) {
    return this.httpService.get(WATCH_LIST.SEARCH_STOCK, queryParams);
  }

  /**Stock detail api */
  getTickerDetail(queryParams: any) {
    return this.httpService.get(`${DASHBOARD.TICKER_DETAIL}/${queryParams}`);
  }

  /**Stock statistics api */
  getTickerStatistics(queryParams: any) {
    return this.httpService.get(`${DASHBOARD.TICKER_STATISTICS}/${queryParams}`);
  }

  /**Stock Graph statistics api */
  getTickerGraphStatistics(queryParams: any) {
    return this.httpService.get(`${DASHBOARD.TICKER_GRAPH_STATISTICS}/${queryParams}`);
  }

  /**Create Portfolio api */
  addPortfolio(body: any) {
    return this.httpService.post( PORTFOLIO.ADD_PORTFOLIO, body, true, false);
  }

  /**Create Portfolio api */
  updatePortfolio(body: any, params: any) {
    return this.httpService.put(
      `${PORTFOLIO.ADD_PORTFOLIO}/${params}`,
      body,
      true,
      false,
    );
  }

  /**Get portfolio list api*/
  getPortfolio(queryParams: any) {
    return this.httpService.get(PORTFOLIO.PORTFOLIO_LIST, queryParams);
  }

  /**Get portfolio details api*/
  getPortfolioDetails(params: any) {
    return this.httpService.get(`${PORTFOLIO.ADD_PORTFOLIO}/${params}`);
  }

  /**Get portfolio details api*/
  deletePortfolio(params: any) {
    return this.httpService.delete(
      `${PORTFOLIO.ADD_PORTFOLIO}/${params}`,
      true,
      false,
    );
  }

  /**Add stock portfolio api*/
  addStockPortfolioApi(body: any) {
    return this.httpService.post(PORTFOLIO.ADD_STOCK, body, true, false);
  }

  /**Delete stock portfolio api */
  deleteStockPortfolio(stockId: any) {
    return this.httpService.delete(
      `${PORTFOLIO.ADD_STOCK}/${stockId}`,
      true,
      false,
    );
  }

  /**Get Trending stock  api*/
  getTrendingStock(params: any) {
    return this.httpService.get(DASHBOARD.TRENDING_STOCK, params);
  }

  /**Get Trending stock  api*/
  getTrendingTicker() {
    return this.httpService.get(DASHBOARD.TRENDING_TICKER);
  }

  /**Portfolio Optimization api */
  portfolioOptimization(body: any) {
    return this.httpService.post(PORTFOLIO.OPTIMIZATION_PORTFOLIO, body);
  }

  /**Portfolio Optimization api */
  dashboardGraphApi(queryParams: any) {
    return this.httpService.get(PORTFOLIO.DASHBOARD_GRAPH, queryParams);
  }

  /**Get News  api */
  getNews(queryParams: any) {
    return this.httpService.get(NEWS.STOCK_NEWS, queryParams);
  }

  /** Search News by ticker api */
  getSearchNews(queryParams: any) {
    return this.httpService.get(`${NEWS.STOCk_NEWS_TICKER}/${queryParams}`);
  }
}
